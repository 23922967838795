import Vue from 'vue';
import App from './App.vue';
import './assets/css/common.scss';
import router from './router';
import './utils/rem.js'
import {
  Menu,
  MenuItem,
  Dialog,
  Carousel,
  CarouselItem
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Dialog)
Vue.use(Carousel)
Vue.use(CarouselItem)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
