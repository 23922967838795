import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "layout" */ '@/pages/home/index.vue'),
  },
  {
    path: '/privacy-protocol',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "layout" */ '@/pages/home/component/privacy.vue'),
  },
  {
    path: '/platform-protocol',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "layout" */ '@/pages/home/component/agreement.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
